import { useState, useEffect } from 'react'

export function getVeeziFilm(id) {
  const req = new Request(`https://api.us.veezi.com/v1/film/${id}`, {
    headers: { VeeziAccessToken: 'sVJR4rtFak-ZQvm87lwoIA2' },
  })

  return fetch(req).then(res => {
    if (res.ok) {
      return res.json()
    }

    return res.text().then(text => {
      throw text
    })
  })
}

export function useVeeziFilm(id) {
  const [veeziFilm, setVeeziFilm] = useState(() => null)

  useEffect(() => {
    const doFetch = () => {
      getVeeziFilm(id).then(film => setVeeziFilm(film))
    }

    if (id) {
      doFetch()
    }

    return () => {}
  }, [id])

  return veeziFilm
}
