import { OutboundLink } from 'gatsby-plugin-google-analytics'
import PropTypes from 'prop-types'
import React from 'react'

import { classes } from '../util/components'
import Image from './image'
import styles from './sponsors.module.css'

const Sponsors = ({ sponsors, className }) => {
  if (!(sponsors || []).length) return null

  return (
    <div className={classes(styles.sponsors, className)}>
      <hr className={styles.separator} />
      {sponsors.map((s, i) => (
        <div className={styles.sponsorsWrapper} key={i}>
          <h3 className={styles.sponsorsTitle}>{s.label}</h3>
          <div
            className={classes(
              styles.sponsorLogos,
              s.sponsorType === 'primary' && styles.sponsorLogosPrimary,
              s.sponsorType === 'secondary' && styles.sponsorLogosSecondary
            )}
          >
            {(s.group || []).map((sponsor, i) => (
              <OutboundLink
                className={styles.sponsorLogo}
                href={sponsor.linkURL}
                key={i}
                target="_blank"
                rel="noopener noreferrer"
              >
                {sponsor.logo && sponsor.logo.childImageSharp ? (
                  <Image
                    imgStyle={{
                      objectFit: 'contain',
                    }}
                    fluid={sponsor.logo.childImageSharp.fluid}
                    alt={sponsor.name}
                    title={sponsor.name}
                  ></Image>
                ) : (
                  sponsor.name
                )}
              </OutboundLink>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

Sponsors.propTypes = {
  sponsors: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
}

export default Sponsors
