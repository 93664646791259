import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  createRef,
} from 'react'
import PropTypes from 'prop-types'

import { flatten } from '../util/array'
import { classes } from '../util/components'
import caret_down from '../images/caret_down.svg'
import styles from './dropdown.module.css'

const findSelectedIndex = (children, selected) => {
  const selectedIndex = children.findIndex(
    ({ props: { value: childValue } }) => childValue === selected
  )

  return selectedIndex
}

const findSelectedValue = (children, val) => {
  const selectedIndex = findSelectedIndex(children, val)
  if (selectedIndex > -1) {
    return children[selectedIndex].props.children
  }

  return null
}

const getSelectedValue = (multiple, children, selected) =>
  multiple
    ? (selected || [])
        .reduce((values, val) => {
          const selectedText = findSelectedValue(children, val)
          return selectedText ? [...values, selectedText] : values
        }, [])
        .join(', ')
    : findSelectedValue(children, selected)

const Dropdown = ({
  children,
  placeholder,
  isHover,
  onClick,
  selected,
  wrapperClassName,
  className,
  multiple,
  error,
}) => {
  const [isOpened, setIsOpened] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const [isClickLocked, setIsClickLocked] = useState(false)
  const [overflowClass, setOverflowClass] = useState('')
  const childrenArr = useRef(
    Array.isArray(children) ? flatten(children) : [children]
  )
  const [childrenRef, setChildrenRef] = useState(
    childrenArr.current.map(() => createRef())
  )

  const currentSelected = useRef(
    findSelectedIndex(childrenArr.current, selected)
  )

  const wrapperRef = useRef()
  const selectedVal = getSelectedValue(multiple, childrenArr.current, selected)

  // Done to prevent the click handler from
  // immediately closing the dropdown on the first click
  const lockdownClick = () => {
    setIsClickLocked(true)
    setTimeout(() => setIsClickLocked(false), 200)
  }

  useEffect(() => {
    childrenArr.current = Array.isArray(children)
      ? flatten(children)
      : [children]
    setChildrenRef(childrenArr.current.map(() => createRef()))
  }, [children])

  useEffect(() => {
    currentSelected.current = findSelectedIndex(childrenArr.current, selected)
  }, [selected])

  const onChildrenClicked = useCallback(
    (val, clickedRef) => {
      if (onClick) onClick(val)
      if (clickedRef && !multiple) clickedRef.current.blur()
    },
    [onClick, multiple]
  )

  const onDropdownOpened = useCallback(() => {
    setTimeout(() => {
      setOverflowClass(styles.listSelectWrapperOverflow)
    }, 200)
  }, [])

  const onDropdownClosed = useCallback(() => {
    setOverflowClass('')
  }, [])

  const internalSetIsOpened = useCallback(
    val => {
      setIsOpened(val)

      if (val) {
        onDropdownOpened()
      } else {
        onDropdownClosed()
      }
    },
    [onDropdownClosed, onDropdownOpened]
  )

  const toggleIsOpened = useCallback(() => {
    setIsOpened(val => !val)

    if (!isOpened) {
      onDropdownClosed()
      childrenRef[currentSelected.current].current.focus()
    } else {
      onDropdownOpened()
      onChildrenClicked(
        childrenArr.current[currentSelected.current].props.value
      )
    }
  }, [
    childrenRef,
    isOpened,
    onChildrenClicked,
    onDropdownClosed,
    onDropdownOpened,
  ])

  const checkDocumentKeyDown = useCallback(
    event => {
      if (isFocused) {
        switch (event.keyCode) {
          // enter
          case 13: {
            if (multiple) {
              onChildrenClicked(
                childrenArr.current[currentSelected.current].props.value
              )
            } else {
              toggleIsOpened()
            }
            event.preventDefault()
            break
          }
          // up
          case 38: {
            if (
              currentSelected.current &&
              childrenRef[currentSelected.current]
            ) {
              childrenRef[currentSelected.current].current.blur()
            }

            currentSelected.current = Math.max(currentSelected.current - 1, 0)

            if (
              currentSelected.current &&
              childrenRef[currentSelected.current]
            ) {
              childrenRef[currentSelected.current].current.focus()
            }

            event.preventDefault()
            break
          }
          // down
          case 40: {
            if (
              currentSelected.current &&
              childrenRef[currentSelected.current]
            ) {
              childrenRef[currentSelected.current].current.blur()
            }

            currentSelected.current = Math.min(
              currentSelected.current + 1,
              childrenRef.length - 1
            )

            if (
              currentSelected.current &&
              childrenRef[currentSelected.current]
            ) {
              childrenRef[currentSelected.current].current.focus()
            }

            event.preventDefault()
            break
          }
          default:
        }
      }
    },
    [childrenRef, isFocused, multiple, onChildrenClicked, toggleIsOpened]
  )

  const addKeyDownListener = () => {
    setTimeout(() => {
      document.addEventListener('keydown', checkDocumentKeyDown)
    })
  }

  const removeKeyDownListener = () => {
    setTimeout(() => {
      document.removeEventListener('keydown', checkDocumentKeyDown)
    })
  }

  const handleOnFocus = () => {
    lockdownClick()
    internalSetIsOpened(true)
    setIsFocused(true)
  }

  const handleOnBlur = () => {
    internalSetIsOpened(false)
    setIsFocused(false)
  }

  const handleOnClick = () => {
    if (isFocused && !isClickLocked) {
      internalSetIsOpened(!isOpened)
    }
  }

  const showPlaceholder = isOpened || !selectedVal

  useEffect(() => {
    addKeyDownListener()

    return () => {
      removeKeyDownListener()
    }
  })

  const isSelected = val =>
    multiple ? (selected || []).includes(val) : selected === val

  return (
    <div
      className={classes(
        styles.dropdown,
        wrapperClassName,
        'inline-block',
        multiple && styles.multiple,
        error && styles.error
      )}
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}
      ref={wrapperRef}
    >
      <div
        className={classes(
          styles.listButton,
          className,
          showPlaceholder && styles.listButtonPlaceholder,
          'flex',
          'flex-row',
          'justify-center',
          'items-center'
        )}
        tabIndex="0"
        onClick={handleOnClick}
      >
        <img src={caret_down} alt="Caret Down"></img>
        {showPlaceholder ? placeholder : selectedVal}
      </div>
      <div
        className={`${styles.listSelectWrapper} flex flex-col
         ${overflowClass}
         ${isOpened ? styles.listSelectWrapperOpen : ''}
         ${isHover ? styles.listSelectWrapperHover : ''}`}
      >
        {childrenArr.current.map(
          ({ props: { value: childValue, children: singleChild } }, i) => {
            return (
              <button
                key={i}
                className={`${styles.listSelect} ${
                  isSelected(childValue) ? styles.listSelected : ''
                }`}
                onClick={ev => onChildrenClicked(childValue, childrenRef[i])}
                ref={childrenRef[i]}
                type="button"
                tabIndex="-1"
              >
                <span>{singleChild}</span>
              </button>
            )
          }
        )}
      </div>
    </div>
  )
}

Dropdown.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  placeholder: PropTypes.string,
  isHover: PropTypes.bool,
  selected: PropTypes.any,
}

Dropdown.defaultProps = {
  onClick: null,
  placeholder: 'Select one',
  isHover: true,
  selected: null,
}

export default Dropdown

export const DropdownItem = ({ value, children }) => {
  return <></>
}

DropdownItem.propTypes = {
  value: PropTypes.any,
  children: PropTypes.node,
}
