import PropTypes from 'prop-types'
import React from 'react'
import slugify from 'slugify'

import Link from '../components/link'
import { classes } from '../util/components'
import styles from './tag-list.module.css'

const TagList = ({ tags, className }) => {
  if (!tags || !tags.length) return null

  return (
    <ul className={classes(styles.tagList, className)}>
      {tags.map((tag, i) => {
        if (tag) {
          return (
            <li className={styles.tag} key={i}>
              <Link to={`/tags/${slugify(tag)}`}>{tag}</Link>
            </li>
          )
        }
        return null
      })}
    </ul>
  )
}

TagList.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string),
}

export default TagList
