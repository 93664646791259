import dayjs from 'dayjs'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Helmet from 'react-helmet'
import slugify from 'slugify'

import Link from '../components/link'
import Banner from '../components/banner'
import Carousel from '../components/carousel'
import CoverImage from '../components/cover-image'
import Modal from '../components/modal'
import Rating from '../components/rating'
import RelatedEvents from '../components/related-events'
import SocialMediaSharing from '../components/social-media-sharing'
import Sponsors from '../components/sponsors'
import TagList from '../components/tag-list'
import TicketBooking from '../components/ticket-booking'
import TicketBookingPreview from '../components/ticket-booking-preview'
import { useScreenSize } from '../hooks/screen-size'
import { useVeeziFilm } from '../hooks/veezi-film'
import { useVeeziWebSessionsAll } from '../hooks/veezi-web-sessions'
import forwardArrow from '../images/forward_arrow.png'
import { classes } from '../util/components'
import getReleasingSchedules from '../util/releasingSchedules'
import EventAttributes from './event-attributes'
import styles from './event.module.css'

export const EventCoverCarousel = ({ location, event }) => {
  const [autoScroll, setAutoScroll] = useState(true)
  const [lock, setLock] = useState(false)
  const { screenSize } = useScreenSize()
  const isMobile = ['xs', 'sm'].includes(screenSize)

  const carouselSlides = (event.carousel || []).map((slide, i) => {
    const imageSrc =
      slide.image && slide.image.childImageSharp
        ? slide.image.childImageSharp.fluid
        : null

    return (
      <>
        {imageSrc ? (
          <Helmet
            meta={[
              {
                property: `og:image`,
                content: `https://theprojector.sg${imageSrc.src}`,
              },
            ]}
          />
        ) : null}
        <CoverImage
          key={i}
          className={styles.coverImage}
          title={event.title}
          image={imageSrc}
          videoUrl={slide.videoUrl}
          onTrailerStart={() => {
            setAutoScroll(false)
            setLock(true)
          }}
          onTrailerPlay={() => {
            setLock(true)
          }}
          onTrailerPause={() => {
            setLock(false)
          }}
        />
      </>
    )
  })

  const ogImage = carouselSlides.find(s => !!s.image)

  return (
    <>
      {ogImage ? (
        <Helmet
          meta={[
            {
              property: `og:image`,
              content: `https://theprojector.sg${ogImage.childImageSharp.fluid.src}`,
            },
          ]}
        />
      ) : null}
      <Carousel
        wrapperClassName={styles.carouselWrapper}
        className={styles.carousel}
        slides={carouselSlides}
        previewSlides={isMobile}
        autoScroll={autoScroll}
        lock={lock}
      />
    </>
  )
}

export const EventHeader = ({ location, event, veeziFilm }) => {
  const imageSrc =
    event.coverImage && event.coverImage.childImageSharp
      ? event.coverImage.childImageSharp.fluid
      : null

  return (
    <>
      <div className={classes('container', styles.eventHeader)}>
        <div className={styles.themesAndCategories}>
          {(event.themes || []).length ? (
            <div className={styles.themes}>
              {event.themes.map((theme, i) => (
                <React.Fragment key={i}>
                  {i > 0 ? ', ' : null}
                  <Link to={theme.slug} className={styles.theme}>
                    {theme.title}
                  </Link>
                </React.Fragment>
              ))}
            </div>
          ) : null}
          {(event.categories || []).length ? (
            <div className={styles.categories}>
              {event.categories.map((category, i) => (
                <React.Fragment key={i}>
                  {i > 0 ? ', ' : null}
                  {event.eventTypes && event.eventTypes.length ? (
                    <Link
                      to={`/${event.eventTypes[0]}s#categories-${category.id}`}
                      className={styles.category}
                    >
                      {category.title}
                    </Link>
                  ) : (
                    <span className={styles.category}>{category.title}</span>
                  )}
                </React.Fragment>
              ))}
            </div>
          ) : null}
        </div>
        <h1 className={styles.title}>
          {event.title}{' '}
          {event.rating ? (
            <Rating className={styles.rating}>{event.rating}</Rating>
          ) : null}
          {event.releasingSchedules && event.releasingSchedules.length
            ? getReleasingSchedules(event.releasingSchedules, true).map(
              (r, i) => (
                <Link key={i} to={`/releasing-schedules/${slugify(r)}`}>
                  <Rating className={styles.releasingSchedules}>{r}</Rating>
                </Link>
              )
            )
            : null}
        </h1>
      </div>
      <div className={styles.cover}>
        {(event.carousel || []).length ? (
          <EventCoverCarousel location={location} event={event} />
        ) : (
          <div className="container">
            <CoverImage
              image={imageSrc}
              videoUrl={veeziFilm && veeziFilm.FilmTrailerUrl}
            />
          </div>
        )}
      </div>
    </>
  )
}

export const EventPageTemplate = ({
  event,
  relatedEvents,
  banner,
  location,
  documentLocation,
  showTicketBooking = true,
  isPreview = false,
}) => {
  
  const veeziFilm = useVeeziFilm(event.veeziFilmId)
  const veeziWebSessions = useVeeziWebSessionsAll({ filmId: event.veeziFilmId })
  const [isTicketOpen, setIsTicketOpen] = useState(false)
  const { screenSize } = useScreenSize()
  const isMobile = ['xs', 'sm'].includes(screenSize)
  const platforms = event.platforms || []
  const buyTicketsCaption = ['vod', 'cinema'].every(p =>
    platforms.includes(p)
  ) ? (
    <>
      Buy Tickets
      <small className="block text-sm">or Watch at Home</small>
    </>
  ) : platforms.includes('vod') ? (
    <>Watch at Home</>
  ) : (
    <>Buy tickets</>
  )

  const isSessionAvailable = !!(veeziWebSessions && veeziWebSessions.length)
  const showVodLink = !!(
    (event.platforms || []).includes('vod') &&
    (event.vodURL || event.vodCallToAction)
  )
  
  var str = '';
  { veeziFilm && veeziWebSessions.length
    && veeziWebSessions.map((s, i) => {
    str+=`'@type':'ListItem','position':${i},'item':{'@type': 'ScreeningEvent',location:${location && location.address},'address': {'@type':'PostalAddress','streetAddress':'6001 Beach Road, Golden Mile Tower','addressLocality':'Singapore','postalCode':'199589','addressCountry':'SG'},'startDate':${dayjs(s.PreShowStartTime).toISOString()},'areaServed':{'@type':'City','name':'Singapore'}}}`})
  }
  
  return (
    <div className={styles.event}>
      <Helmet
        title={event.title}
        meta={[
          {
            property: `og:title`,
            content: event.title,
          },
          {
            name: `twitter:title`,
            content: event.title,
          },
          {
            name: `description`,
            content: event.blurb,
          },
          {
            property: `og:description`,
            content: event.blurb,
          },
          {
            name: `twitter:description`,
            content: event.blurb,
          },
        ]}
      >
        {veeziFilm && veeziWebSessions.length
          ? veeziWebSessions.map((s, i) => (
            <script key={i} type="application/ld+json">
              {JSON.stringify({
                '@context': 'http://schema.org',
                '@type': 'ScreeningEvent',
                name: event.title,
                description: event.blurb,
                location: {
                  '@type': 'MovieTheater',
                  name: 'The Projector',
                  screenCount: 3,
                  address: location && location.address,
                  image:
                    location &&
                      location.image &&
                      location.image.childImageSharp
                      ? location.image.childImageSharp.fluid.src
                      : undefined,
                },
                workPresented: {
                  '@type': 'Movie',
                  name: event.title,
                  sameAs: event.imdbUrl,
                  image:
                    event.coverImage && event.coverImage.childImageSharp
                      ? event.coverImage.childImageSharp.fluid.src
                      : undefined,
                  director:
                    event.attributes &&
                    (event.attributes.find(a => a.name === 'director') || {})
                      .value,
                },
                performer:
                  event.attributes &&
                  (event.attributes.find(a => a.name === 'actors') || {})
                    .value,
                duration: `P${veeziFilm.Duration}M`,
                startDate: dayjs(s.PreShowStartTime).toISOString(),
              })}
            </script>
          ))
          : null}
      </Helmet>

      <Helmet>
      <script type="application/ld+json">
      {JSON.stringify({
      "@context": "https://schema.org",
      "@type": "Movie",
      name:event.title,
      image:location &&
      location.image &&
      location.image.childImageSharp
      ? location.image.childImageSharp.fluid.src
      : undefined,
      duration: `P${veeziFilm && veeziFilm.Duration}M`,
      datePublished: event.attributes && (event.attributes.find(a => a.name === 'year') || {})
        .value,
      sameAs: event.imdbUrl,
      "director": {
      "@type": "Person",
      name: event.attributes &&
      (event.attributes.find(a => a.name === 'director') || {})
        .value,
      },
      contentRating: event.rating,
      "genre": "DRAMA",
      "availableLanguage":
      {
      "@type": "Language",
      name: event.attributes && (event.attributes.find(a => a.name === 'language') || {})
        .value,
      } ,
      "subtitleLanguage": event.attributes && (event.attributes.find(a => a.name === 'subtitles') || {})
      .value,
      "countryOfOrigin": {
      "@type": "Country",
      "name": "South Korea"
      },
      "actor": [
      {
      "@type": "Person",
      name:  event.attributes &&
      (event.attributes.find(a => a.name === 'actors') || {})
        .value
      }
      ],
      "subjectOf":{
        "@type": "ItemList",
        "itemListElement": [str]
      }
    
      })}
      </script>
      </Helmet>

      <EventHeader
        location={documentLocation}
        event={event}
        veeziFilm={veeziFilm}
      />
      <div className="container">
        <div className="flex flex-col md:flex-row justify-between">
          <div className={styles.contentLeft}>
            <SocialMediaSharing title={event.title} slug={event.slug} />
            <EventAttributes event={event} />
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{ __html: event.html }}
            ></div>
            <Sponsors sponsors={event.sponsors} />
            <TagList tags={event.tags} className={styles.tags} />
          </div>
          <div
            className={`${styles.contentRight} ${isMobile && isTicketOpen ? styles.contentRightOpen : ''
              }`}
          >
            {isPreview ? (
              <TicketBookingPreview
                className={styles.ticketPreview}
                event={event}
              />
            ) : showTicketBooking ? (
              <>
                <Modal
                  open={isTicketOpen}
                  handleClose={() => setIsTicketOpen(false)}
                >
                  <TicketBooking
                    event={event}
                    veeziWebSessions={veeziWebSessions}
                  />
                </Modal>
                <div className="hidden md:block">
                  <TicketBooking
                    event={event}
                    veeziWebSessions={veeziWebSessions}
                  />
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
      {showTicketBooking && (
        <div
          className={`${styles.btnBuy} z-30 flex flex-row items-center`}
        >
          {isSessionAvailable && <button
            className={`${styles.btnBuyAction} btn-primary ${!showVodLink ? styles.borderRight2px : ''}`}
            onClick={() => setIsTicketOpen(true)}
          >
            {'Buy Tickets'}
          </button>}

          {showVodLink && <Link
            to={event.vodURL || ''}
            target="_blank"
            rel="noopener noreferrer"
            className={`${styles.btnRentAction} btn-primary`}
          >
            {'Rent Movie'}
          </Link>}
        </div>
      )}
      {(relatedEvents || []).length ? (
        <div className="pb-20 px-8 lg:px-0">
          <h3 className={styles.relatedEventsTitle}>
            You may be interested in
          </h3>
          <RelatedEvents events={relatedEvents} />
        </div>
      ) : null}
      {banner ? (
        <div className="container">
          <Banner banner={banner} />
        </div>
      ) : null}
    </div>
  )
}

const EventPage = ({ location, data }) => {
  const event = {
    html: data.event.html,
    ...data.event.frontmatter,
    ...data.event.fields,
  }
  const relatedEvents = data.relatedEvents.edges.map(e => ({
    ...e.node.frontmatter,
    ...e.node.fields,
  }))
  const banner = data.banner.fields.banner.frontmatter

  const cinemaLocation =
    data.locations.edges.length && data.locations.edges[0].node.frontmatter

  event.themes =
    event.themes &&
    event.themes.map(t => ({
      ...t.fields,
      ...t.frontmatter,
    }))

  event.categories =
    event.categories &&
    event.categories.map(t => ({
      ...t.fields,
      ...t.frontmatter,
    }))

  return (
    <EventPageTemplate
      event={event}
      relatedEvents={relatedEvents}
      banner={banner}
      location={cinemaLocation}
      documentLocation={location}
    />
  )
}

EventPage.propTypes = {
  data: PropTypes.shape({
    event: PropTypes.object,
    relatedEvents: PropTypes.object,
  }),
}

export default EventPage

export const pageQuery = graphql`
  query EventBySlug($id: String!, $relatedEventIds: [String]) {
    event: markdownRemark(id: { eq: $id }) {
      html
      fields {
        slug
        themes {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
        categories {
          fields {
            slug
          }
          frontmatter {
            id
            title
          }
        }
        venue {
          fields {
            slug
          }
          frontmatter {
            title
            accessibility
            screens {
              veeziScreenId
            }
          }
        }
      }
      frontmatter {
        eventTypes
        tags
        veeziFilmId
        bookingURL
        title
        callToAction
        coverImage {
          childImageSharp {
            fluid(maxWidth: 1280, maxHeight: 720, quality: 92) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        carousel {
          image {
            childImageSharp {
              fluid(maxWidth: 1280, maxHeight: 720, quality: 92) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          videoUrl
        }
        blurb
        blurbHtml
        rating
        releasingSchedules
        attributes {
          name
          value
        }
        terms
        startDate
        endDate
        platforms
        vodURL
        vodCallToAction
        sponsors {
          label
          sponsorType
          group {
            logo {
              childImageSharp {
                fluid(maxWidth: 150, maxHeight: 80, quality: 90, fit: CONTAIN) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            name
            linkURL
          }
        }
        screenings {
          veeziSessionId
          closedCaptions
        }
      }
    }
    relatedEvents: allMarkdownRemark(
      filter: {
        fields: {
          collection: { eq: "films-and-events" }
          id: { in: $relatedEventIds }
        }
      }
    ) {
      edges {
        node {
          fields {
            id
            slug
          }
          frontmatter {
            title
            coverImage {
              childImageSharp {
                fluid(maxWidth: 400, maxHeight: 232, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    banner: markdownRemark(fields: { slug: { eq: "/event/" } }) {
      fields {
        banner {
          frontmatter {
            title
            image {
              childImageSharp {
                fluid(maxWidth: 590, maxHeight: 300, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            body
            callToAction
            linkURL
          }
        }
      }
    }
    locations: allMarkdownRemark(
      filter: { fields: { collection: { eq: "locations" } } }
      sort: { fields: [frontmatter___title], order: [ASC] }
    ) {
      edges {
        node {
          frontmatter {
            image {
              childImageSharp {
                fluid(maxWidth: 1160, maxHeight: 741, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            address
            title
          }
        }
      }
    }
  }
`
