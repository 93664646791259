import PropTypes from 'prop-types'
import React from 'react'

import styles from './event-attributes.module.css'

const ATTRIBUTE_LABELS = {
  advisory: 'Advisory',
  director: 'Directed by',
  actors: 'Cast',
  performers: 'Performed by',
  duration: 'Duration',
  year: 'Year',
  language: 'Language',
  subtitles: 'Subtitles',
}

const EventAttributes = ({ event }) => {
  if (!(event.attributes || []).length) {
    return null
  }

  return (
    <dl className={styles.attributes}>
      {event.attributes.map((a, i) => (
        <React.Fragment key={i}>
          <dt>{ATTRIBUTE_LABELS[a.name]}:</dt>
          <dd>{a.value}</dd>
        </React.Fragment>
      ))}
    </dl>
  )
}

EventAttributes.propTypes = {
  event: PropTypes.object,
}

export default EventAttributes
