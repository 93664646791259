  import React from 'react'

import Link from '../components/link'
import styles from './banner.module.css'
import Image from './image'

const Banner = ({ banner }) => (
  <div className={`${styles.bannerWrapper} flex flex-col md:flex-row`}>
    <div className={`flex-1`}>
      <Image
        fluid={banner.image && banner.image.childImageSharp.fluid}
        alt={banner.title}
        title={banner.title}
      ></Image>
    </div>

    <div className={`${styles.bannerContent} flex-1`}>
      <h2>{banner.title}</h2>
      <p>{banner.body}</p>
      <Link className="btn-primary inline-block" to={banner.linkURL}>
        {banner.callToAction}
      </Link>
    </div>
  </div>
)

export default Banner
