import PropTypes from 'prop-types'
import React, { useState, useRef, useEffect } from 'react'
import { Swipeable } from 'react-touch'

import Link from '../components/link'
import { useScreenSize } from '../hooks/screen-size'
import { classes } from '../util/components'
import ArrowButton from './arrow-button'
import Image from './image'
import styles from './related-events.module.css'

const TRANSITION_IN_PROGRESS = 'inProgress'
const TRANSITION_DONE = 'done'
const TRANSITION_TIME = 100

const RelatedEvents = ({ events }) => {
  const [current, setCurrent] = useState(0)
  const [transitionState, setTransitionState] = useState(TRANSITION_DONE)
  const [isFirstSlideHovered, setIsFirstSlideHovered] = useState(false)
  const [isLastSlideHovered, setIsLastSlideHovered] = useState(false)
  const { screenSize } = useScreenSize()

  const isMobile = ['xs', 'sm'].includes(screenSize)

  const onEventMouseEnter = useRef()
  const onEventMouseLeave = useRef()

  useEffect(() => {
    onEventMouseEnter.current = (isFirst, isLast) => {
      if (isMobile) {
        setIsFirstSlideHovered(true)
        setIsLastSlideHovered(true)
      } else {
        if (isFirst) {
          setIsFirstSlideHovered(true)
        } else if (isLast) {
          setIsLastSlideHovered(true)
        }
      }
    }

    onEventMouseLeave.current = (isFirst, isLast) => {
      if (isMobile) {
        setIsFirstSlideHovered(false)
        setIsLastSlideHovered(false)
      } else {
        if (isFirst) {
          setIsFirstSlideHovered(false)
        } else if (isLast) {
          setIsLastSlideHovered(false)
        }
      }
    }
  }, [isMobile])

  const slides = (events || [])
    .reduce(
      (slides, event) => {
        const slideSize = isMobile ? 1 : 2

        let slide
        if (slides[slides.length - 1].length === slideSize) {
          slides.push([])
        }

        slide = slides[slides.length - 1]
        slide.push(event)
        return slides
      },
      [[]]
    )
    .map((slide, i) => {
      return (
        <div key={i} className={styles.eventSlide}>
          {slide.map((event, a) => (
            <Link
              className={`${styles.event} block cursor-pointer border-highlight`}
              key={a}
              onMouseEnter={() =>
                onEventMouseEnter.current(a === 0, a === slide.length - 1)
              }
              onMouseLeave={() =>
                onEventMouseLeave.current(a === 0, a === slide.length - 1)
              }
              onFocus={() =>
                onEventMouseEnter.current(a === 0, a === slide.length - 1)
              }
              onBlur={() =>
                onEventMouseLeave.current(a === 0, a === slide.length - 1)
              }
              to={event.slug}
              title={event.title}
            >
              <Image
                fluid={
                  event.coverImage &&
                  event.coverImage.childImageSharp &&
                  event.coverImage.childImageSharp.fluid
                }
                className={`${styles.coverImage}`}
              />
              <div className={styles.eventOverlay}></div>
              <div className={styles.eventText}>
                <h4 className={styles.eventTitle}>{event.title}</h4>
                <button className="btn-transparent">Find out more</button>
              </div>
            </Link>
          ))}
        </div>
      )
    })

  const goPrevious = () => {
    setTransitionState(TRANSITION_IN_PROGRESS)

    setTimeout(() => {
      setTransitionState(TRANSITION_DONE)
      if (current === slides.length - 1) {
        setCurrent(0)
      } else {
        setCurrent(current + 1)
      }
    }, TRANSITION_TIME)
  }

  const goNext = () => {
    setTransitionState(TRANSITION_IN_PROGRESS)

    setTimeout(() => {
      setTransitionState(TRANSITION_DONE)
      if (current === 0) {
        setCurrent(slides.length - 1)
      } else {
        setCurrent(current - 1)
      }
    }, TRANSITION_TIME)
  }

  return (
    <div className={styles.events}>
      <Swipeable onSwipeLeft={() => goNext()} onSwipeRight={() => goPrevious()}>
        <div
          className={classes(
            styles.eventSlides,
            transitionState === TRANSITION_IN_PROGRESS &&
              styles.eventSlidesMoving
          )}
        >
          {slides[current]}
        </div>
      </Swipeable>
      <ArrowButton
        direction="left"
        className={`${styles.previous} ${isFirstSlideHovered &&
          styles.hoveredWhiteCircle}`}
        onClick={goPrevious}
      />
      <ArrowButton
        direction="right"
        className={`${styles.next} ${isLastSlideHovered &&
          styles.hoveredWhiteCircle}`}
        onClick={goNext}
      />
    </div>
  )
}

RelatedEvents.propTypes = {
  events: PropTypes.arrayOf(PropTypes.object),
}

export default RelatedEvents
