import { OutboundLink } from 'gatsby-plugin-google-analytics'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import Modal from '../components/modal'
import emailIcon from '../images/email.svg'
import facebookIcon from '../images/facebook.svg'
import shareIcon from '../images/share.svg'
import telegramIcon from '../images/telegram.svg'
import twitterIcon from '../images/twitter.svg'
import whatsappIcon from '../images/whatsapp.svg'
import { classes } from '../util/components'
import styles from './social-media-sharing.module.css'

const SocialMediaSharing = ({ title, slug, className }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [shareUrl, setShareUrl] = useState('')

  const copyToClipboard = () => navigator.clipboard.writeText(shareUrl)

  useEffect(() => {
    setShareUrl(`${document.location.origin}${slug}`)
  }, [slug])

  if (!slug) {
    return null
  }

  return (
    <>
      <Modal
        open={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        className={classes(styles.modalWrapper)}
      >
        <div className={styles.modal}>
          <div className={styles.modalHeader}>
            <span>Share The Event</span>
            <span className={styles.title}>{title}</span>
          </div>
          <div className={styles.modalIcons}>
            <OutboundLink
              href={`https://www.facebook.com/sharer.php?u=${shareUrl}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={facebookIcon} alt="Facebook" />
            </OutboundLink>
            <OutboundLink
              href={`https://twitter.com/intent/tweet?url=${shareUrl}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={twitterIcon} alt="twitter" />
            </OutboundLink>
            <OutboundLink
              href={`https://t.me/share/url?url=${shareUrl}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={telegramIcon} alt="telegram" />
            </OutboundLink>
            <OutboundLink
              href={`https://api.whatsapp.com/send?text=${shareUrl}`}
              target="_blank"
              rel="noopener noreferrer"
              data-action="share/whatsapp/share"
            >
              <img src={whatsappIcon} alt="WhatsApp" />
            </OutboundLink>
            <OutboundLink
              href={`mailto:?body=${shareUrl}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={emailIcon} alt="Email" />
            </OutboundLink>
          </div>

          <div className={styles.modalLink}>
            <input type="text" value={shareUrl} disabled />
            <button
              onClick={e => {
                copyToClipboard()
                e.target.classList.add(styles.copied)
                e.target.innerHTML = 'Copied!'
                setTimeout(
                  target => {
                    target.classList.remove(styles.copied)
                    target.innerHTML = 'Copy Link'
                  },
                  2000,
                  e.target
                )
              }}
            >
              Copy Link
            </button>
          </div>
        </div>
      </Modal>

      <div
        className={classes(styles.share, className)}
        onClick={() => setIsModalOpen(true)}
      >
        <img src={shareIcon} alt="Share" />
        Share With Friends
      </div>
    </>
  )
}

SocialMediaSharing.propTypes = {
  title: PropTypes.string,
  slug: PropTypes.string,
  className: PropTypes.string,
}

export default SocialMediaSharing
